var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100 w-100"},[_c('div',{staticClass:"w-100 position-absolute",staticStyle:{"z-index":"-1","margin-left":"-2rem","margin-top":"calc(-2rem - 55px)","height":"100vh"},style:('background: url(' + _vm.imgUrl + ') center center / cover;')}),_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('div',{staticClass:"position-relative",staticStyle:{"width":"30%","min-width":"36rem"}},[_c('b-card',{staticClass:"font-text text-center jsf-header-title"},[_vm._v("锦什坊街叁拾伍号 ")])],1)]),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"3"}},[_c('b-card',{staticClass:"d-flex flex-column justify-content-between jsf-card-backdrop",attrs:{"no-body":""}},[_c('vue-perfect-scrollbar',{staticClass:"px-2 my-2",staticStyle:{"height":"calc(90vh - 244px)"}},[_c('b-row',_vm._l((_vm.subSystems),function(item,index){return _c('b-col',{key:index,staticClass:"d-flex flex-column justify-content-between pb-1",attrs:{"cols":"6"}},[_c('b-button',{ref:item.key + '_enter_button',refInFor:true,staticClass:"font-text text-left d-flex align-items-center py-1 jsf-button-backdrop",attrs:{"block":"","disabled":!_vm.hasPermission(
                                'subsystems',
                                'subsystems/' + item.key,
                                'view'
                            ),"to":{
                                    name: 'project-jsf-subsystems',
                                    params: {
                                        project_id: 72,
                                        system_key: item.key,
                                        project_name:
                                            _vm.$store.state.projects.selected
                                                .project_name,
                                        title: item.name,
                                        icon: item.icon,
                                    },
                                },"variant":"flat-primary"}},[_c('b-avatar',{staticClass:"mr-1 jsf-avatar",attrs:{"size":"3.5rem","variant":"light-primary"}},[_c('i',{staticClass:"iconfont text-primary jsf-primary",class:item.icon,staticStyle:{"font-weight":"300"},style:({
                                        'font-size': item.size
                                            ? item.size + 'rem'
                                            : '2rem',
                                    })})]),_c('div',{staticClass:"text-primary jsf-primary text-truncate",staticStyle:{"font-size":"1.2rem","line-height":"1.2"}},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)}),1)],1)],1)],1),_c('b-col',{attrs:{"cols":"3","offset":"6"}},[_c('b-card',{staticClass:"d-flex flex-column justify-content-between jsf-card-backdrop",staticStyle:{"height":"calc(90vh - 202px)"}},[_c('b-tabs',{staticClass:"jsf-tabs"},_vm._l((_vm.alarmList),function(item,key){return _c('b-tab',{key:'alarm_type_' + key,attrs:{"title":item['title']}},[_c('vue-perfect-scrollbar',{staticClass:"jsf35-homepage-scroll-notice",staticStyle:{"height":"calc(90vh - 482px)"},on:{"mouseenter":function($event){return _vm.scroll_stop('.jsf35-homepage-scroll-notice')},"mouseleave":function($event){return _vm.scroll_active(
                                    '.jsf35-homepage-scroll-notice'
                                )}}},[(
                                item['list'] && item['list'].length > 0
                            )?_c('app-timeline',{staticClass:"mt-1 jsf-timeline"},_vm._l((item['list']),function(alarmItem,alarmItemIndex){return _c('app-timeline-item',{key:'alarm_item_' + alarmItemIndex,staticClass:"pb-1 text-shadow",staticStyle:{"cursor":"pointer"},attrs:{"subtitle":alarmItem['text'],"time":alarmItem['time'],"title":alarmItem['title'],"variant":"warning"},on:{"click":function($event){return _vm.alarmToSubSystemPage(
                                            alarmItem['title']
                                        )}}})}),1):_c('h6',{staticClass:"text-center mt-5"},[(item['title'] === '设备报警')?[_c('span',[_vm._v("设备运行良好，")])]:_vm._e(),(item['title'] === '消防报警')?[_c('span',[_vm._v("消防措施到位，")])]:_vm._e(),(item['title'] === '安防报警')?[_c('span',[_vm._v("安防措施到位，")])]:_vm._e(),_vm._v(" 暂无报警信息 ")],2)],1)],1)}),1),_c('b-row',{staticClass:"mt-1"},_vm._l((_vm.subPages),function(subPage,subPageIndex){return _c('b-col',{key:subPageIndex,attrs:{"cols":"6"}},[_c('b-button',{ref:subPage.route + '_enter_button',refInFor:true,staticClass:"font-text text-left d-flex align-items-center py-1 jsf-button-backdrop",class:subPageIndex < 2 ? 'mb-1' : '',attrs:{"to":{
                                name: subPage.route,
                                params: {
                                    project_id: 72,
                                    project_name:
                                        _vm.$store.state.projects.selected
                                            .project_name,
                                    title: subPage.title,
                                    icon: subPage.icon,
                                },
                            },"variant":"flat-primary"}},[_c('b-avatar',{staticClass:"mr-1 jsf-avatar",attrs:{"size":"3.5rem","variant":"light-primary"}},[_c('feather-icon',{staticClass:"jsf-primary",attrs:{"icon":subPage.icon,"size":"30"}})],1),_c('div',{staticClass:"jsf-primary text-truncate",staticStyle:{"font-size":"1.2rem","line-height":"1.2"}},[_vm._v(" "+_vm._s(subPage.title)+" ")])],1)],1)}),1)],1)],1)],1),_c('b-row',_vm._l((_vm.statisticsNumber),function(item,key){return _c('b-col',{key:key,attrs:{"cols":"2"}},[_c('b-card',{staticClass:"jsf-card-backdrop cursor-pointer",on:{"click":function($event){return _vm.showDetailIframe(item)}}},[_c('div',{staticClass:"d-flex align-items-center justify-content-between",staticStyle:{"height":"10vh"}},[_c('b-avatar',{staticClass:"jsf-avatar",attrs:{"variant":'light-' + item.color,"size":"5rem"}},[_c('i',{staticClass:"iconfont jsf-secondary-red",class:[
                            item.color
                                ? ("text-" + (item.color))
                                : 'avatar-color',
                            item.icon ],staticStyle:{"font-weight":"100"},style:({
                                'font-size': item.size
                                    ? item.size + 'rem'
                                    : '3.5rem',
                            })})]),_c('div',{staticClass:"font-digit text-right jsf-primary"},[_c('h4',{staticClass:"font-text jsf-grey-lighten mb-0 mt-1",class:item.color
                                ? ("text-" + (item.color))
                                : 'avatar-color'},[_c('span',{staticStyle:{"font-size":"0.85rem","color":"#9e9e9e"}},[_vm._v("今日")]),_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',[_c('span',{staticStyle:{"font-size":"2.5rem"}},[_vm._v(_vm._s(item.finished))]),_c('span',{staticClass:"text-muted",staticStyle:{"font-size":"1.5rem"}},[_vm._v(" / "+_vm._s(item.total))])])])],1)])],1)}),1),_c('b-modal',{staticClass:"detailIframeModal",attrs:{"size":"xl","centered":"","hide-footer":"","title":_vm.detailIframeModalShow.title},model:{value:(_vm.detailIframeModalShow.show),callback:function ($$v) {_vm.$set(_vm.detailIframeModalShow, "show", $$v)},expression:"detailIframeModalShow.show"}},[_c('b-embed',{attrs:{"id":"jsf-homepage-detail-iframe","src":_vm.detailIframeModalShow.src,"allow-cross-origin":"","allowfullscreen":"","loading":"lazy","type":"iframe"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }